import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  Container,
  Header as SUIHeader,
  Grid,
  Button as SUIButton,
  Image,
} from "semantic-ui-react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import TextContainer from "../components/text-container"

const GridRow = styled(Grid.Row)`
  @media only screen and (min-width: 1200px) {
    padding: 4em 4em 0em 4em !important;
  }
`

const GridColumn = styled(Grid.Column)`
  @media only screen and (min-width: 1200px) {
    margin: 0em 2em !important;
  }
`

const Header = styled(SUIHeader)`
  font-size: ${props =>
    (props.as === "h1" && "2.6em") ||
    (props.as === "h2" && "2.2em") ||
    (props.as === "h3" && "1.5em")};
`

const Button = styled.a`
  width: 100%;
  padding: 2em 0em !important;
  margin-top: 2em !important;
  text-transform: uppercase !important;
  background: #d7203d !important;
  color: white !important;
`

const IndexPage = () => (
  <Layout>
    <SEO title="FAQs" keywords={[`host family english`, `hfe`]} />
    <Hero
      title="FAQS"
      dimmed
      img="https://res.cloudinary.com/mystay-international/image/upload/v1555991231/Host%20Family%20English/iStock_35612966_XLARGE_preview-e1522985931585-1024x220.jpg"
    />
    <Grid
      style={{
        marginLeft: "0em",
        marginRight: "0em",
      }}
    >
      <GridRow>
        <Grid.Column>
          <h2>What is HFE Mentoring</h2>
          <p>
            HFE Mentoring is an official online Homestay Host training program
            that upskills homestay hosts and qualifies them as an official HFE
            Mentor. Mentoring is a bridge. In our case it connects caring adults
            with young people. There are young people in communities across the
            world who want to learn English and are eager to find adults willing
            to help them, listen to them – and through the communication and
            interaction that is at the heart of the mentoring relationship –
            provide them with a safe and meaningful environment in which to
            progress and practice their English language skill. In fact, it has
            been shown that mentoring is one of the most  powerful ways to
            combat the gaps between people in our society. Mentoring is a proven
            support method that can help young people achieve their personal and
            educational goals – including helping English language learners to
            improve their language skills and confidence. It brings young people
            together with caring hosts, who can offer guidance, support, and
            encouragement in a planned way, helping the student to develop their
            personal skills and strengthen their confidence.
          </p>
          <h2>What is a Mentor and what do they do?</h2>
          <p>
            Mentors are caring individuals, who provide young people with
            support, guidance, and friendship. As a Mentor, you could describe
            yourself as a wise guide – providing opportunities for the student
            to practice English in a safe, non-threatening, and non-judgmental
            environment, and a guide who models good English and who corrects
            the student’s English errors sensitively and appropriately. This is
            something we all need.
          </p>
          <h2>How is a Mentor different from a coach?</h2>
          <p>
            Mentoring is a more informal association, focused on building a
            two-way relationship: focused on supporting the student. The Mentor
            usually has a wealth of experience to share as they help guide the
            students to understand, practice, and develop their English language
            skills in relevant, real-life situations, conversations and
            interactions.
          </p>
          <p>
            We are all familiar with the role of a coach – especially in the
            world of sports. Coaching is a more formal and structured
            association
          </p>
          <p>Some wise person once said.</p>
          <p>
            <b>
              <i>
                “A coach has some great questions for your answers; a mentor has
                some great answers for your questions”
              </i>
            </b>
          </p>
          <h2>How will mentoring help your international students?</h2>
          <p>
            There are many studies that have shown that being part of a
            mentoring relationship can have major benefits for the young person
            involved.
          </p>
          <p>
            Those studies have shown that Mentors can help keep students focused
            on their education, and can help to improve their academic skills.
          </p>
          <p>
            Students who meet regularly with a Mentor are 37% less likely to
            skip a class. 52% less likely than their peers to skip a day of
            school or college, 59% improve their grades, and 86% go on to higher
            education! We strongly believe that as you support a student’s
            English language learning, you – and they – will see similar
            successes in terms of the student’s online lessons they have
            undertaken to do. Mentors can also help improve a young person’s
            self-esteem by providing support for students trying to think
            through new challenges they may be facing – those language
            challenges, such as acquiring new vocabulary or using idioms.
            Mentors can teach young people how to relate well to all kinds of
            people, strengthen their English communication skills, and a good
            Mentor can build the student’s confidence to use those new skills.
          </p>
          <p>The Commonwealth Fund Survey report found that:</p>
          <ul>
            <li>
              Three-quarters of the mentors surveyed reported that their
              experience had had a “very positive” effect on their lives. They
              felt that mentoring provided a break from their busy professional
              lives and a chance to give something back.
            </li>
            <li>
              Eighty-three percent indicated that they learned or gained
              something personally from their mentoring experience, including
              feeling that they were a better person, had increased patience, a
              feeling of effectiveness, and a chance to acquire new skills
            </li>
          </ul>
        </Grid.Column>
      </GridRow>
      <Grid.Row centered>
        <Grid.Column mobile="8" tablet="5" largeScreen="3">
          <Button className={`ui button`} href="/contact-us">
            Contact Us
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Layout>
)

export default IndexPage
